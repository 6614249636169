export default [
  // auth-login
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/center/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // auth-register
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/center/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // auth-forgot-password
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/center/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // user-account
  {
    path: '/user-account',
    name: 'user-account',
    component: () => import('@/views/center/user-account/UserAccount.vue'),
    meta: {
      pageTitle: 'User Account',
      breadcrumb: [
        {
          text: 'User Account',
          active: true,
        },
      ],
    },
  },
  // auth-verify-email
  {
    path: '/verifyemail/:id/:token',
    name: 'auth-verify-email',
    component: () => import('@/views/center/authentication/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  // auth-reset-password
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/center/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
